import { Badge, GridItem, useToast } from "@chakra-ui/react";
import React from "react";
import { QAPlatformStateGenerate } from "./config";
import ProductUpdateModal from "./ProductUpdateModal";
import {
  onUpdatePublishState,
  userCanPublish,
  userCanUnpublish,
} from "utils/helpers";

const ExternalQAProductCardChildren = ({
  product,
  userRole,
  productId,
  revalidateProducts,
  revalidateStateCounts,
}) => {
  const toast = useToast();

  const { qa_state, published_at, vtoAvailable, name } = product;

  const canPublish = userCanPublish(qa_state, vtoAvailable);
  const canUnpublish = userCanUnpublish(qa_state, published_at);

  const platformState = QAPlatformStateGenerate(
    qa_state,
    published_at,
    vtoAvailable,
    userRole
  );

  async function onUpdate() {
    try {
      await onUpdatePublishState(canPublish, canUnpublish, productId);

      revalidateProducts();
      revalidateStateCounts();

      toast({
        title: canPublish ? "Publish" : "Unpublish",
        description: `${name} ${canPublish ? "Published" : "Unpublished"}!`,
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.log("Something went wrong:: ", error);
      toast({
        title: "Errored",
        description: "Something went wrong!",
        status: "error",
        duration: 3000,
      });
    }
  }

  return (
    <>
      <GridItem>
        <Badge
          fontSize={{ base: ".85rem", lg: "1rem" }}
          fontWeight="semibold"
          rounded="md"
          colorScheme={platformState.colorScheme}
          variant={platformState.variant}
        >
          {platformState.text}
        </Badge>
      </GridItem>
      <GridItem placeSelf="end" alignSelf="start" />
      <GridItem alignSelf="end">
        <ProductUpdateModal
          btnText={
            canPublish ? "publish" : canUnpublish ? "unpublish" : "publish"
          }
          productName={name}
          canUpdate={canPublish || canUnpublish}
          onUpdate={onUpdate}
          canUnpublish={canUnpublish}
        />
      </GridItem>
      <GridItem placeSelf="end" />
    </>
  );
};

export default ExternalQAProductCardChildren;
