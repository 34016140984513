import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Product from "./pages/Sku";
import Meltwater from "./pages/2022";
import NotFound from "./pages/NotFound";
import Catalogue from "./pages/Catalogue";
import VTO from "./pages/VTO";
import QAIndex from "./pages/QA/Home";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/qa/:slug" element={<QAIndex />} />
      <Route path="/catalogue/:slug" element={<Catalogue />} />
      <Route path="/sku/:slug" element={<Product />} />
      <Route path="/2022" element={<Meltwater />} />
      <Route path="/embed/vto" element={<VTO />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
