import { useEffect, useState } from "react";
import useSWR, { useSWRInfinite } from "swr";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { debounce } from "lodash";

import {
  Badge,
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { fetcher, fetchOrganizationToken } from "../../utils/api";
import {
  capitalize,
  ImportScriptMeshkraftViewer,
  ImportScriptMeshkraftAr,
  getQueryStringParameters,
} from "../../utils/helpers";

import Header from "components/Header";
import ProductCard from "components/ProductCard";
import Login from "components/Login";
import InternalQAProductCardChildren from "pages/QA/InternalQAProductCardChildren";
import ExternalQAProductCardChildren from "./ExternalQAProductCardChildren";
import ExportTableModal from "./ExportTableModal";

const PAGE_LIMIT = 250;

function Home() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState(null);

  const { slug } = useParams();
  const navigate = useNavigate();
  const searchState = searchParams.get("state") || "";
  const searchName = searchParams.get("name") || "";

  const isUserInternal = user?.role?.type === "internal_qa";
  const isUserAdmin = user?.role?.type === "admin_qa";

  useEffect(() => {
    const userFromLocalStorage = localStorage.getItem("loggedin-user");

    if (userFromLocalStorage) {
      setUser(JSON.parse(userFromLocalStorage));
    }
  }, []);

  let filters = "";

  const getKey = (pageIndex, previousPageData) => {
    // reached the end
    if (previousPageData && previousPageData.length < PAGE_LIMIT) return null;

    // Extract category from URL
    const category = getQueryStringParameters().category;

    if (searchState) {
      filters += "&_where[_or][0][0][qa_state]=" + searchState;
    }

    if (searchName) {
      filters += "&name_contains=" + searchName;
    }
    if (category) {
      filters += "&category=" + category;
    }

    // first page, we don't have `previousPageData`
    if (pageIndex === 0)
      return `${process.env.REACT_APP_API_URL}/organizations/${slug}/products?_limit=${PAGE_LIMIT}${filters}`;

    // add the cursor to the API endpoint

    return `${
      process.env.REACT_APP_API_URL
    }/organizations/${slug}/products?_limit=${PAGE_LIMIT}&_start=${
      pageIndex * PAGE_LIMIT
    }${filters}`;
  };

  const {
    data: productsData,
    size,
    setSize,
    revalidate: revalidateProducts,
  } = useSWRInfinite(!!user ? getKey : null, fetcher);

  const { data: countData, error: countError } = useSWR(
    !!user
      ? `${process.env.REACT_APP_API_URL}/organizations/${slug}/product-count`
      : null,
    fetcher
  );

  const {
    data: statesCountData,
    error: statesCountError,
    revalidate: revalidateStateCounts,
  } = useSWR(
    !!user
      ? `${process.env.REACT_APP_API_URL}/organizations/${slug}/product-state-count`
      : null,
    fetcher
  );

  const { data: tokenData, error: tokenError } = useSWR(
    !!productsData?.length ? productsData[0][0]?.organization : null,
    fetchOrganizationToken
  );

  const count = countData ? countData.count : 0;
  // We can now calculate the number of all products
  let totalProducts = 0;

  if (productsData) {
    for (let i = 0; i < productsData.length; i++) {
      totalProducts += productsData[i].length;
    }
  }

  const handleSearchNameQuery = debounce((e) => {
    let query = e.target.value;

    if (query) {
      searchParams.set("name", query);
      setSearchParams(searchParams);
      navigate(`/qa/${slug}?${searchParams}`);
    } else {
      searchParams.delete("name");
      setSearchParams(searchParams);
    }
  }, 500);

  const handleSearchStateQuery = debounce((state) => {
    // https://docs-v3.strapi.io/developer-docs/latest/developer-resources/content-api/content-api.html#filters

    state =
      state.toLowerCase() === "ready"
        ? "vto-ready&_where[_or][0][1][vtoAvailable]=true&_where[_or][1][0][qa_state]=ar-ready&_where[_or][1][1][vtoAvailable]=false"
        : state.toLowerCase() === "wip"
        ? isUserInternal || isUserAdmin
          ? "wip"
          : "ar-ready&_where[_or][0][1][vtoAvailable]=true&_where[_or][1][0][qa_state]=wip&_where[_or][2][0][qa_state]=ar-approval&_where[_or][3][0][qa_state]=vto-approval"
        : state;

    if (state) {
      searchParams.set("state", state);
      setSearchParams(searchParams);
      navigate(`/qa/${slug}?${searchParams}`);
    } else {
      searchParams.delete("state");
      setSearchParams(searchParams);
    }
  }, 500);

  const handleCategoryChange = (e) => {
    let category = e.target.value;

    const query = searchParams.get("query");

    const state = searchParams.get("state");

    if (category) {
      searchParams.set("category", category);
      setSearchParams(searchParams);
      navigate(`/qa/${slug}?${searchParams}`);
    }

    if (query) {
      searchParams.set("query", query);
      setSearchParams(searchParams);
      navigate(`/qa/${slug}?${searchParams}`);
    }

    if (state) {
      searchParams.set("state", state);
      setSearchParams(searchParams);
      navigate(`/qa/${slug}?${searchParams}`);
    }

    if (!category) {
      searchParams.delete("category");
      setSearchParams(searchParams);
    }
  };

  const categoryOptions = countData?.categories;

  if (!user) {
    return <Login afterLogin={(user) => setUser(user)} />;
  } else {
    return (
      <>
        {tokenData?.token && (
          <ImportScriptMeshkraftViewer
            resourceUrl={
              "https://unpkg.com/meshkraft-viewer@latest/dist/meshkraft-viewer.min.js"
            }
            meshkraftKey={tokenData?.token}
          />
        )}
        {tokenData?.token && (
          <ImportScriptMeshkraftAr
            resourceUrl={
              "https://unpkg.com/meshkraft-viewer@latest/dist/meshkraft-ar.min.js"
            }
            meshkraftKey={tokenData?.token}
          />
        )}

        <meshkraft-viewer height="0" />
        <Header
          hasLogout={true}
          title={`${capitalize(slug)} Catalogue (${totalProducts} / ${count})`}
        />

        <Box padding="5">
          <Flex
            flexDir={{ base: "column", lg: "row" }}
            mb="4"
            justifyContent="space-between"
            width="100%"
            position="relative"
            gap="5"
          >
            <Flex
              justifyContent="flex-start"
              left={0}
              top={{ base: "0", lg: "-1" }}
              w={{ base: "full", lg: "300px" }}
            >
              <InputGroup size="md">
                <InputLeftElement pointerEvents="none" children="🔎" />
                <Input
                  defaultValue={searchName}
                  type="text"
                  placeholder="Search"
                  id="search"
                  name="search"
                  onChange={handleSearchNameQuery}
                />
              </InputGroup>
            </Flex>
            <Flex w={{ base: "full", lg: "200px" }} justifyContent="flex-end">
              {countData && (
                <Select
                  onChange={handleCategoryChange}
                  placeholder="Categories"
                >
                  {categoryOptions?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Select>
              )}
            </Flex>
          </Flex>
          <Flex
            position="relative"
            flexDir={{ base: "column", md: "row" }}
            alignItems="center"
            justifyContent="center"
            gap="5"
            fontSize={{ base: "1rem", lg: "1.25rem" }}
            mt={{ base: "14", lg: "0" }}
            mb="4"
            mx="auto"
          >
            <Flex position="absolute" left="0" top="0">
              <ExportTableModal
                products={productsData?.flat()}
                slug={slug}
                productCounts={countData?.count}
                filters={filters}
              />
            </Flex>
            <Text fontWeight="Bold">Summary:</Text>
            <Flex
              gap="2"
              alignItems="center"
              justifyContent="center"
              fontWeight="semibold"
              flexWrap="wrap"
            >
              <Badge
                fontSize={{ base: "0.85rem", lg: "1rem" }}
                rounded="md"
                fontWeight="semibold"
                cursor="pointer"
                _hover={{
                  opacity: ".8",
                }}
                onClick={() => {
                  searchParams.delete("state");
                  setSearchParams(searchParams);
                }}
              >
                All
              </Badge>
              {statesCountData &&
                Object.entries(statesCountData)
                  .filter(([_, value]) => Boolean(value))
                  ?.map(([key, value]) => {
                    return (
                      <Badge
                        key={key}
                        fontSize={{ base: "0.85rem", lg: "1rem" }}
                        rounded="md"
                        fontWeight="semibold"
                        cursor="pointer"
                        _hover={{
                          opacity: ".8",
                        }}
                        onClick={() => handleSearchStateQuery(key)}
                      >
                        {value} {key === "wip" ? "Work In Progress" : key}
                      </Badge>
                    );
                  })}
            </Flex>
          </Flex>
          {!productsData ? (
            <Spinner />
          ) : (
            <SimpleGrid columns={[1, 2, 3]} spacing="5">
              {productsData?.map((products) => {
                return products?.map((product) => (
                  <ProductCard
                    key={product.id}
                    product={product}
                    token={tokenData?.token}
                  >
                    {user?.role?.type === "admin_qa" ||
                    user?.role?.type === "internal_qa" ? (
                      <InternalQAProductCardChildren
                        product={product}
                        userRole={user?.role?.type}
                        productId={product.id}
                        revalidateProducts={revalidateProducts}
                        revalidateStateCounts={revalidateStateCounts}
                      />
                    ) : (
                      <ExternalQAProductCardChildren
                        product={product}
                        userRole={user?.role?.type}
                        productId={product.id}
                        revalidateProducts={revalidateProducts}
                        revalidateStateCounts={revalidateStateCounts}
                      />
                    )}
                  </ProductCard>
                ));
              })}
              {totalProducts !== count && (
                <button onClick={() => setSize(size + 1)}>
                  <Text fontSize="lg">
                    <b>Load More ➕</b>
                  </Text>
                </button>
              )}
            </SimpleGrid>
          )}
        </Box>
      </>
    );
  }
}

export default Home;
