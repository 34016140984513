import React from "react";

import { Badge, GridItem, useToast } from "@chakra-ui/react";

import { updateProductState } from "utils/api";
import { QAPlatformStateGenerate } from "./config";
import {
  onUpdatePublishState,
  userCanPublish,
  userCanUnpublish,
} from "utils/helpers";

import ProductUpdateModal from "./ProductUpdateModal";

const InternalQAProductCardChildren = ({
  product,
  userRole,
  productId,
  revalidateProducts,
  revalidateStateCounts,
}) => {
  const toast = useToast();

  const { qa_state, vtoAvailable, published_at, name } = product;

  const isAdmin = userRole === "admin_qa";

  const canPublish = isAdmin && userCanPublish(qa_state, vtoAvailable);
  const canUnpublish = isAdmin && userCanUnpublish(qa_state, published_at);
  const canApprove = qa_state === "vto-approval" || qa_state === "ar-approval";

  let btnText = "Approve";

  if (canPublish) {
    btnText = "Publish";
  } else if (canUnpublish) {
    btnText = "Unpublish";
  }

  function QALabelGenerate(_qa_state, _vtoAvailable) {
    if (
      _qa_state === "published" ||
      !!published_at ||
      qa_state === "unpublished"
    )
      return;

    let label = "";

    if (_vtoAvailable) {
      if (_qa_state === "vto-ready") {
        label = "VTO Ready";
      } else if (_qa_state === "vto-approval") {
        label = "VTO Approval";
      } else if (_qa_state === "ar-ready") {
        label = "AR Ready";
      } else if (_qa_state === "ar-approval") {
        label = "AR Approval";
      } else {
        label = "Work In Progress";
      }
    } else {
      if (_qa_state === "ar-ready") {
        label = "Ready";
      } else if (_qa_state === "ar-approval") {
        label = "Content Approval";
      } else {
        label = "Work In Progress";
      }
    }

    return label;
  }

  const stateLabel = QALabelGenerate(qa_state, vtoAvailable);
  const platformState = QAPlatformStateGenerate(
    qa_state,
    published_at,
    vtoAvailable,
    userRole
  );

  async function onPublish() {
    try {
      await onUpdatePublishState(canPublish, canUnpublish, productId);

      revalidateProducts();
      revalidateStateCounts();

      toast({
        title: canPublish ? "Publish" : "Unpublish",
        description: `${name} ${canPublish ? "Published" : "Unpublished"}!`,
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.log("Something went wrong:: ", error);
      toast({
        title: "Errored",
        description: "Something went wrong!",
        status: "error",
        duration: 3000,
      });
    }
  }

  async function onApprove() {
    let _qa_state = "";

    if (qa_state === "vto-approval") {
      _qa_state = "vto-ready";
    } else if (qa_state === "ar-approval") {
      _qa_state = "ar-ready";
    }

    const payload = {
      qa_state: _qa_state,
    };

    try {
      await updateProductState(payload, productId);
      revalidateProducts();
      revalidateStateCounts();
      toast({
        title: "Approve",
        description: `${name} approved!`,
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.log("Something went wrong:: ", error);
      toast({
        title: "Errored",
        description: "Something went wrong!",
        status: "error",
        duration: 3000,
      });
    }
  }

  return (
    <>
      <GridItem>
        <Badge
          fontSize={{ base: ".85rem", lg: "1rem" }}
          fontWeight="semibold"
          rounded="md"
          colorScheme={platformState.colorScheme}
          variant={platformState.variant}
        >
          {platformState.text}
        </Badge>
      </GridItem>
      <GridItem placeSelf="end" alignSelf="start">
        <Badge
          textTransform="capitalize"
          fontSize={{ base: ".85rem", lg: "1rem" }}
          fontWeight="semibold"
          rounded="md"
          variant="outline"
          colorScheme="blue"
          px="3"
        >
          {stateLabel}
        </Badge>
      </GridItem>
      <GridItem alignSelf="end">
        <ProductUpdateModal
          canUpdate={canApprove || canPublish || canUnpublish}
          productName={name}
          onUpdate={canApprove ? onApprove : onPublish}
          btnText={btnText}
        />
      </GridItem>
      <GridItem placeSelf="end" />
    </>
  );
};

export default InternalQAProductCardChildren;
