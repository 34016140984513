import { useState } from 'react';

import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Image,
  Link,
  Stack,
  Switch,
  useBreakpointValue,
} from '@chakra-ui/react';

const ProductCard = ({ product, token, children }) => {
  const [activate3D, setActivate3D] = useState(false);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const { SKU, images, assets, name } = product;

  return (
    <Box borderWidth="1px" rounded="lg" alignItems="center" flexBasis="45%">
      <Center position="relative">
        <Grid
          position="absolute"
          w="100%"
          left={0}
          right={0}
          top={0}
          bottom={0}
          p={4}
          templateColumns="repeat(2, 1fr)"
          templateRows="repeat(2, 1fr)"
        >
          {children}
        </Grid>
        {activate3D ? (
          <meshkraft-viewer
            sku={SKU}
            show-dimensions
            config={`{"exposure": 1.0, "ar-modes": "scene-viewer webxr quick-look fallback", "ar-scale": "auto", "field-of-view":"40deg", "max-field-of-view": "45deg", "min-field-of-view": "40deg"}`}
            style={{ width: '100%' }}
          />
        ) : (
          <Image
            boxSize="xs"
            objectFit="contain"
            fallbackSrc="/placeholder.jpg"
            src={images?.[0]?.url}
          />
        )}
      </Center>
      <Box p="4">
        <Link href={`https://viewer.artlabs.ai/sku/${SKU}?token=${token}`}>
          <Heading size="md" mb="2" isTruncated>
            {name}
          </Heading>
          <Heading size="sm" mb="2" isTruncated opacity={0.7}>
            {SKU}
          </Heading>
        </Link>
        <Stack direction="row" align="center" justify="space-between">
          <Box>
            <Button onClick={() => meshkraftAR.startAR(SKU)}>
              {isMobile ? 'AR' : 'Start AR'}
            </Button>
            {assets?.vto && (
              <Link
                isExternal
                style={{ textDecoration: 'none' }}
                href={`https://tech-preview.artlabs.ai/vto/qa?sku=${SKU}&token=${token}#meshkraft-quick-vto=${SKU}`}
              >
                <Button ml={2} colorScheme="facebook">
                  {isMobile ? 'VTO' : 'Start VTO'}
                </Button>
              </Link>
            )}
          </Box>
          {assets?.glb && (
            <div>
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormLabel htmlFor="3d-switch" mb="0">
                  3D
                </FormLabel>
                <Switch
                  id="3d-switch"
                  onChange={(e) => {
                    setActivate3D(e.nativeEvent.target.checked);
                  }}
                />
              </FormControl>
            </div>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default ProductCard;
