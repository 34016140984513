import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { updateProductState } from "./api";

export const ImportScript = ({ resourceUrl }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl]);
};

export const ImportScriptMeshkraftViewer = ({ resourceUrl, meshkraftKey }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.type = "module";
    script.setAttribute("data-meshkraft-key", meshkraftKey);
    script.setAttribute("data-meshkraft-drafts", "true");
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl, meshkraftKey]);
};

export const ImportScriptMeshkraftAr = ({ resourceUrl, meshkraftKey }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.type = "module";
    script.setAttribute("data-meshkraft-key", meshkraftKey);
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => meshkraftAR.setConfig({ debugMode: true });

    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl, meshkraftKey]);
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const onLogout = () => {
  localStorage.removeItem("meshkraftApiToken");
  localStorage.removeItem("loggedin-user");

  window.location.reload();
};

export const capitalize = (str) => {
  let firstLetterUpperCase = str[0].toUpperCase();
  let remainingStr = str.slice(1);
  return firstLetterUpperCase + remainingStr;
};

export const parseHash = (str) => {
  var pieces = str.split("&"),
    params = {},
    i,
    parts;
  // process each query pair
  for (i = 0; i < pieces.length; i++) {
    parts = pieces[i].split("=");
    if (parts.length < 2) {
      parts.push("");
    }
    params[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
  }
  return params;
};

export const isObjectEmpty = (obj) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

const openIOSQuickLook = (url) => {
  const modelUrl = url;

  const anchor = document.createElement("a");
  anchor.setAttribute("rel", "ar");
  anchor.setAttribute("href", modelUrl);

  anchor.addEventListener(
    "message",
    function (event) {
      console.log("BANNER PRESSED", event);
    },
    false
  );

  const img = document.createElement("img");
  anchor.appendChild(img);
  anchor.click();
  anchor.removeChild(img);
};

const openSceneViewer = (url, title) => {
  // This is necessary because the original URL might have query
  // parameters. Since we're appending the whole URL as query parameter,
  // ? needs to be turned into & to not lose any of them.
  const noArViewerSigil = "#model-viewer-no-ar-fallback";
  const gltfSrc = url;
  const location = window.location.toString();
  const locationUrl = new URL(location);
  const modelUrl = new URL(gltfSrc, location);

  locationUrl.hash = noArViewerSigil;

  // modelUrl can contain title/link/sound etc.
  // These are already URL-encoded, so we shouldn't do that again here.
  let intentParams = `?file=${modelUrl.toString()}&title=${title}&mode=ar_preferred`;

  if (!gltfSrc.includes("&disable_occlusion=")) {
    intentParams += `&disable_occlusion=true`;
  }
  if (this.arScale === "fixed") {
    intentParams += `&resizable=false`;
  }
  if (this.arPlacement === "wall") {
    intentParams += `&enable_vertical_placement=true`;
  }

  const intent = `intent://arvr.google.com/scene-viewer/1.0${intentParams}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${encodeURIComponent(
    locationUrl.toString()
  )};end;`;

  const anchor = document.createElement("a");
  anchor.setAttribute("href", intent);
  anchor.click();
};

const displayProductAr = (productFull, arMode) => {
  if (arMode === "quick-look") {
    const usdzAsset = productFull.assets.usdz;

    if (!usdzAsset) {
      throw Error("USDZ asset not found");
    }

    return openIOSQuickLook(usdzAsset.url);
  } else if (arMode === "scene-viewer") {
    const glbAsset = productFull.assets.glb;

    if (!glbAsset) {
      throw Error("GLB asset not found");
    }

    return openSceneViewer(glbAsset.url, productFull.name);
  } else {
    throw Error(`Invalid arMode: ${arMode}`);
  }
};

export function getQueryStringParameters() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

export async function onUpdatePublishState(
  canPublish,
  canUnpublish,
  productId
) {
  let payload = {};

  if (canPublish) {
    payload = {
      qa_state: "published",
      published_at: new Date().toISOString(),
    };
  }

  if (canUnpublish) {
    payload = {
      qa_state: "unpublished",
      published_at: null,
    };
  }

  try {
    const res = updateProductState(payload, productId);
    return res;
  } catch (error) {
    return error;
  }
}

export function userCanPublish(qa_state, vtoAvailable) {
  return (
    ["vto-ready", "unpublished"].includes(qa_state) ||
    (qa_state === "ar-ready" && !vtoAvailable)
  );
}

export function userCanUnpublish(qa_state, published_at) {
  return qa_state === "published" || !!published_at;
}

// Keeping it here for future use
// function DisplayAr({ sku, onExit, token }) {
//   onExit = onExit || function () {};

//   const [isMobile, setIsMobile] = React.useState(false);
//   const [arMode, setArMode] = React.useState(undefined);
//   const [isModalClosed, setIsModalClosed] = React.useState(false);
//   const [productFull, setProductFull] = React.useState(null);

//   const toast = useToast();

//   // for modal
//   const { isOpen, onOpen, onClose } = useDisclosure();

//   // for isMobile
//   React.useEffect(() => {
//     const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//     let check = false;
//     // eslint-disable-next-line
//     if (
//       /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
//         userAgent
//       ) ||
//       /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
//         userAgent.substr(0, 4)
//       )
//     ) {
//       check = true;
//     }

//     setIsMobile(check);
//   }, []);

//   // for setArMode
//   React.useEffect(() => {
//     const IS_ANDROID = /android/i.test(navigator.userAgent);
//     const IS_FIREFOX = /firefox/i.test(navigator.userAgent);
//     const IS_OCULUS = /OculusBrowser/.test(navigator.userAgent);

//     const IS_SCENEVIEWER_CANDIDATE = IS_ANDROID && !IS_FIREFOX && !IS_OCULUS;

//     const IS_AR_QUICKLOOK_CANDIDATE = (() => {
//       const tempAnchor = document.createElement("a");

//       return Boolean(
//         tempAnchor.relList &&
//           tempAnchor.relList.supports &&
//           tempAnchor.relList.supports("ar")
//       );
//     })();

//     if (IS_AR_QUICKLOOK_CANDIDATE) {
//       setArMode("quick-look");
//     } else if (IS_SCENEVIEWER_CANDIDATE) {
//       setArMode("scene-viewer");
//     }
//   }, []);

//   // for productFull
//   React.useEffect(() => {
//     async function productFetcher() {
//       try {
//         const response = await fetchProduct(token, sku);
//         setProductFull(response);
//       } catch (error) {
//         toast({
//           title: "Product does not exists.",
//           description: sku,
//           status: "error",
//           duration: 9000,
//           isClosable: true,
//         });

//         return null;
//       }
//     }
//     productFetcher();
//   }, [sku, toast, token]);

//   const onCloseWrapper = () => {
//     setIsModalClosed(true);
//     onClose();
//     onExit();
//   };

//   if (!productFull) {
//     return null;
//   }

//   if (isObjectEmpty(productFull.assets)) {
//     toast({
//       title: "Models are not ready yet.",
//       description: sku,
//       status: "warning",
//       duration: 9000,
//       isClosable: true,
//     });

//     return null;
//   }

//   if (!isMobile) {
//     const url = new URL(document.location);
//     url.hash = `quick-ar=${sku}`;

//     const qrCode = (
//       <QRCode
//         value={url.toString()}
//         bgColor="#fff"
//         fgColor="#000"
//         level="H"
//         size={250}
//       />
//     );

//     if (!isOpen && !isModalClosed) {
//       onOpen();
//     }

//     return (
//       <Box position="absolute" top="1" right="1" zIndex="1">
//         <Modal isOpen={isOpen} onClose={onCloseWrapper} isCentered>
//           <ModalOverlay />
//           <ModalContent>
//             <ModalCloseButton />
//             <ModalBody textAlign="center">
//               <Text fontSize="3xl" fontWeight="bold">
//                 Scan QR Code
//               </Text>
//               <Text fontSize="l" marginBottom={4}>
//                 Point your mobile phone camera to the QR code below to
//                 experience in Augmented Reality
//               </Text>
//               <Center>{qrCode}</Center>
//             </ModalBody>

//             <ModalFooter>
//               <Button colorScheme="blue" mr={3} onClick={onCloseWrapper}>
//                 Close
//               </Button>
//             </ModalFooter>
//           </ModalContent>
//         </Modal>
//       </Box>
//     );
//   } else {
//     if (!arMode) {
//       return null;
//     }

//     try {
//       displayProductAr(productFull, arMode);
//     } catch (err) {
//       toast({
//         title: err,
//         description: sku,
//         status: "error",
//         duration: 9000,
//         isClosable: true,
//       });
//     }

//     return null;
//   }
// }
