import axios from "axios";
import axiosInstance from "./axiosInstance";

export async function fetcher(url) {
  const config = {
    url,
  };
  const data = await axiosInstance(config);

  return data;
}

export async function loginUser(username, password) {
  const url = `${process.env.REACT_APP_API_URL}/auth/local`;

  const config = {
    url,
    method: "POST",
    data: {
      identifier: username,
      password: password,
    },
  };

  const data = await axiosInstance(config);

  return data;
}

export const changePublicationState = async (token, productId, action) => {
  const url = `${process.env.REACT_APP_API_URL}/products/${productId}`;

  const published_at = action === "publish" ? Date.now() : null;

  const payload = {
    published_at: published_at,
  };

  const settings = {
    headers: {
      Authorization: "Bearer " + token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const response = await axios.put(url, payload, settings);

  return response.data;
};

export async function fetchProduct(token, sku) {
  const config = {
    url: `/product/${sku}?token=${token}&drafts=true`,
  };
  const data = await axiosInstance(config);

  return data;
}

export async function fetchOrganizationToken(organizationId) {
  const config = {
    url: `/organizations/${organizationId}/token`,
  };

  const data = await axiosInstance(config);

  return data;
}

export async function updateProductState(payload, productId) {
  const url = `${process.env.REACT_APP_API_URL}/products/${productId}`;

  const config = {
    url,
    method: "PUT",
    data: payload,
  };

  const data = await axiosInstance(config);

  return data;
}
