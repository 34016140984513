import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR, { useSWRInfinite } from "swr";
import {
  Box,
  Heading,
  SimpleGrid,
  Switch,
  Text,
  Image,
  FormControl,
  FormLabel,
  Stack,
  Center,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  Link,
  Button,
  useBreakpointValue,
  Select,
  Flex,
} from "@chakra-ui/react";
import { debounce } from "lodash";

import {
  capitalize,
  ImportScriptMeshkraftViewer,
  ImportScriptMeshkraftAr,
  getQueryStringParameters,
} from "../utils/helpers";
import { fetcher } from "../utils/api";
import { useQuery } from "../utils/helpers";
import Spinner from "../components/Spinner";
import History from "../components/History";
import Header from "../components/Header";

const PAGE_LIMIT = 200;

function Catalogue() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const queryHook = useQuery();

  const token = queryHook.get("token");
  const category = queryHook.get("category");
  const query = queryHook.get("query");
  const addToCart = queryHook.get("addToCart");

  const getKey = (pageIndex, previousPageData) => {
    // reached the end
    if (previousPageData && previousPageData.length < PAGE_LIMIT) return null;

    let filters = "";

    // Extract category from URL
    const category = getQueryStringParameters().category;

    if (category) {
      filters += "&category=" + category;
    }

    if (query) {
      filters += "&name_contains=" + query;
    }

    // first page, we don't have `previousPageData`
    if (pageIndex === 0)
      return `${process.env.REACT_APP_API_URL}/organizations/${slug}/products?token=${token}&_limit=${PAGE_LIMIT}${filters}`;

    // add the cursor to the API endpoint
    return `${
      process.env.REACT_APP_API_URL
    }/organizations/${slug}/products?token=${token}&_limit=${PAGE_LIMIT}&_start=${
      pageIndex * PAGE_LIMIT
    }${filters}`;
  };

  const handleSearchQuery = debounce((e) => {
    let query = e.target.value;

    if (query && !category) {
      navigate(`${History.location.pathname}?token=${token}&query=${query}`);
    } else if (query && category) {
      navigate(
        `${History.location.pathname}?token=${token}&query=${query}&category=${category}`
      );
    } else if (category) {
      navigate(
        `${History.location.pathname}?token=${token}&category=${category}`
      );
    } else {
      navigate(
        `${History.location.pathname}?token=${token}&addToCart=${addToCart}`
      );
    }
  }, 1000);

  const handleCategoryChange = (e) => {
    let category = e.target.value;

    if (query && !category) {
      navigate(`${History.location.pathname}?token=${token}&query=${query}`);
    } else if (!query && category) {
      navigate(
        `${History.location.pathname}?token=${token}&category=${category}`
      );
    } else if (query && category) {
      navigate(
        `${History.location.pathname}?token=${token}&query=${query}&category=${category}`
      );
    } else {
      navigate(`${History.location.pathname}?token=${token}`);
    }
  };

  const { data, size, setSize } = useSWRInfinite(slug ? getKey : null, fetcher);

  //returning the countData and related product categories
  const { data: countData, error } = useSWR(
    slug
      ? `${process.env.REACT_APP_API_URL}/organizations/${slug}/product-count?token=${token}`
      : null,
    fetcher
  );

  const count = countData?.count || 0;
  // We can now calculate the number of all products
  let totalProducts = 0;

  if (data) {
    for (let i = 0; i < data.length; i++) {
      totalProducts += data[i].length;
    }
  }

  const categoryOptions = countData?.categories || [];

  return (
    <>
      {token && (
        <ImportScriptMeshkraftViewer
          resourceUrl={
            "https://unpkg.com/meshkraft-viewer@latest/dist/meshkraft-viewer.min.js"
          }
          meshkraftKey={token}
        />
      )}
      {token && (
        <ImportScriptMeshkraftAr
          resourceUrl={
            "https://unpkg.com/meshkraft-viewer@latest/dist/meshkraft-ar.min.js"
          }
          meshkraftKey={token}
        />
      )}

      <ImportScriptMeshkraftAr
        resourceUrl={"https://unpkg.com/meshkraft-vto@latest/dist/index.min.js"}
        meshkraftKey={token}
      />

      <meshkraft-viewer height="0" />
      <Header
        title={`${capitalize(slug)} Catalogue (${totalProducts} / ${count})`}
      />

      <Box padding="5">
        <HStack spacing="24px" mb="4" justifyContent="space-between">
          <Box w={{ base: "full", lg: "200px" }}>
            <InputGroup size="md">
              <InputLeftElement pointerEvents="none" children="🔎" />
              <Input
                defaultValue={query}
                type="text"
                placeholder="Search"
                onChange={handleSearchQuery}
              />
            </InputGroup>
          </Box>
          <Flex w={{ base: "full", lg: "200px" }} justifyContent="flex-end">
            <Select onChange={handleCategoryChange} placeholder="Categories">
              {categoryOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Select>
          </Flex>
        </HStack>
        {!data ? (
          <Spinner />
        ) : (
          <SimpleGrid columns={[1, 2, 3]} spacing="5">
            {data.map((products) => {
              return products.map((product) => (
                <ProductItem
                  key={product.id}
                  product={product}
                  token={token}
                  sku={product.SKU}
                  addToCart={addToCart}
                  data={data}
                />
              ));
            })}
            {totalProducts !== count && (
              <button onClick={() => setSize(size + 1)}>
                <Text fontSize="lg">
                  <b>Load More ➕</b>
                </Text>
              </button>
            )}
          </SimpleGrid>
        )}
      </Box>
    </>
  );
}

const ProductItem = ({ product, token, sku, addToCart }) => {
  const [activate3D, setActivate3D] = useState(false);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Box
      key={product.id}
      borderWidth="1px"
      rounded="lg"
      alignItems="center"
      flexBasis="45%"
    >
      <Center>
        {activate3D ? (
          <meshkraft-viewer
            sku={product.SKU}
            style={{width: "100%"}}
            config={`{"exposure": 1.0, "ar-modes": "scene-viewer webxr quick-look fallback", "ar-scale": "auto", "field-of-view":"40deg", "max-field-of-view": "45deg", "min-field-of-view": "40deg"}`}
          />
        ) : (
          <Image
            boxSize="xs"
            objectFit="contain"
            fallbackSrc="/placeholder.jpg"
            src={product.images?.[0]?.url}
          />
        )}
      </Center>
      <Box p="4">
        <Link href={`https://viewer.artlabs.ai/sku/${sku}?token=${token}`}>
          <Heading size="md" mb="2" isTruncated>
            {product.name}
          </Heading>
          <Heading size="sm" mb="2" isTruncated opacity={0.7}>
            {product.SKU}
          </Heading>
        </Link>
        <Stack direction="row" align="center" justify="space-between">
          <Box>
            <Button onClick={() => meshkraftAR.startAR(sku)}>
              {isMobile ? "AR" : "Start AR"}
            </Button>
            {product?.assets?.vto && (
              <Link
                isExternal
                style={{ textDecoration: "none" }}
                href={
                  addToCart === "true"
                    ? `https://tech-preview.artlabs.ai/vto/qa?sku=${sku}&token=${token}&showAction=true#meshkraft-quick-vto=${sku}`
                    : `https://tech-preview.artlabs.ai/vto/qa?sku=${sku}&token=${token}#meshkraft-quick-vto=${sku}`
                }
              >
                <Button ml={2} colorScheme="facebook">
                  {isMobile ? "VTO" : "Start VTO"}
                </Button>
              </Link>
            )}
          </Box>
          {product?.assets?.glb && (
            <div>
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormLabel htmlFor="3d-switch" mb="0">
                  3D
                </FormLabel>
                <Switch
                  id="3d-switch"
                  onChange={(e) => {
                    setActivate3D(e.nativeEvent.target.checked);
                  }}
                />
              </FormControl>
            </div>
          )}
        </Stack>
      </Box>
      {/* <Text fontSize="lg">SKU: {product.SKU}</Text> */}
    </Box>
  );
};

export default Catalogue;
