import { ArrowRightIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { BeatLoader } from "react-spinners";
import { loginUser } from "utils/api";

function Login({ afterLogin }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const user = await loginUser(username, password);

      if (!!user) {
        localStorage.setItem("meshkraftApiToken", user.jwt);
        localStorage.setItem("loggedin-user", JSON.stringify(user.user));

        setIsLoading(false);
        afterLogin(user.user);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("Invalid Username or Password");
    }
  };

  return (
    <Center height="90vh">
      <Stack
        as="form"
        onSubmit={onSubmit}
        id="login-form"
        style={{ width: 300 }}
      >
        <FormControl isRequired mb="3">
          <FormLabel htmlFor="username">Username</FormLabel>
          <Input
            id="username"
            placeholder="Username"
            value={username}
            onChange={(e) => {
              if (errorMessage) {
                setErrorMessage("");
              }
              setUsername(e.target.value);
            }}
            width="100%"
          />
        </FormControl>

        <FormControl isRequired mb="3">
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            id="password"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => {
              if (errorMessage) {
                setErrorMessage("");
              }
              setPassword(e.target.value);
            }}
            width="100%"
          />
        </FormControl>

        <FormControl>
          {!!errorMessage && (
            <Alert status="error" mb="3" rounded="md" fontSize=".9rem">
              <AlertIcon />
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}
          <Button
            rightIcon={<ArrowRightIcon boxSize={3} />}
            isLoading={isLoading}
            loadingText="Logining"
            spinner={<BeatLoader size={8} color="white" />}
            w="full"
            colorScheme="blue"
            type="submit"
          >
            Login
          </Button>
        </FormControl>
      </Stack>
    </Center>
  );
}

export default Login;
