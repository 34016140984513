import React from "react";

import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { TbTableExport } from "react-icons/tb";

function ExportTableModal({ products, productCounts, slug, filters }) {
  const [selectedFields, setSelectedFields] = React.useState([]);
  const [isExporting, setIsExporting] = React.useState(false);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const shouldFetchAllItems = products?.length < productCounts;

  const [firstProduct] = products ? products : [];

  const availableOptions = Object.entries(firstProduct || {}).reduce(
    (acc, [key, value]) => {
      if (
        typeof value !== "object" &&
        typeof value !== "boolean" &&
        !key.startsWith("_")
      ) {
        acc.push({ label: key, value: key });
      }

      return acc;
    },
    []
  );

  async function exportCSV() {
    setIsExporting(true);
    let productsSelectedInfo;

    if (shouldFetchAllItems) {
      try {
        const allProducts = await fetchProducts();

        productsSelectedInfo = getProductsInfo(allProducts);
      } catch (err) {
        console.error(err);
        setIsExporting(false);
        onClose();
        toast({
          title: "Export Failed",
          description: "Oopsie. Something went wrong!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      productsSelectedInfo = getProductsInfo(products);
    }

    const result = createCSV(productsSelectedInfo);

    downloadCSV(result);
    setIsExporting(false);
    onClose();
  }

  function createCSV(productsSelectedInfo) {
    const csvRows = [];
    const headers = Object.keys(productsSelectedInfo[0]);

    csvRows.push(headers.join(","));

    for (const row of productsSelectedInfo) {
      const values = headers.map((header) => {
        const val = row[header];
        return `"${val}"`;
      });

      csvRows.push(values.join(","));
    }

    const result = csvRows.join("\n");

    return result;
  }

  function downloadCSV(result) {
    const newUrl = window.URL.createObjectURL(new Blob([result]));
    const link = document.createElement("a");
    link.setAttribute("href", newUrl);
    link.setAttribute("download", "result.csv");
    document.body.appendChild(link);

    link.click();
  }

  async function fetchProducts() {
    const token = localStorage.getItem("meshkraftApiToken");

    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/${slug}/products?_limit=-1${filters}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const allProducts = await data.json();
    return allProducts;
  }

  function getProductsInfo(products) {
    const productsSelectedInfo = products?.reduce((acc, curr, idx) => {
      let eachItem = {
        no: idx + 1,
      };

      for (const test of selectedFields) {
        eachItem[test] = curr[test];
      }

      acc.push(eachItem);

      return acc;
    }, []);

    return productsSelectedInfo;
  }

  return (
    <>
      <Flex
        role="button"
        tabIndex="0"
        aria-pressed="false"
        cursor="pointer"
        alignItems="center"
        gap="5"
        bgColor="purple.500"
        color="white"
        px="3"
        py="1"
        rounded="md"
        w="50px"
        transition="ease-in"
        transitionDuration="0.20s"
        _focus={{
          width: "full",
          bgColor: "purple.400",
        }}
        _hover={{
          width: "full",
          bgColor: "purple.400",
        }}
        onClick={onOpen}
        onKeyDown={(e) => {
          if (e.code === "Space" || e.code === "") {
            onOpen();
          }
        }}
      >
        <Icon as={TbTableExport} fontSize="1.25rem" fontWeight="bold" />
        <Text fontSize="1rem">Export</Text>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export Products Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Select fields"
              isMulti
              options={availableOptions}
              onChange={(selectedOptionsArr) => {
                const _keys = selectedOptionsArr.map(
                  (selectedOption) => selectedOption.label
                );

                setSelectedFields(_keys);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={exportCSV}
              ml={3}
              colorScheme="green"
              isDisabled={!selectedFields.length}
              isLoading={isExporting}
              loadingText="Exporting..."
            >
              Export
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ExportTableModal;
