import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { FaTwitter } from "react-icons/fa";

const Footer = ({ slug, arButton }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Box
        as="footer"
        position="relative"
        top="0"
        width="full"
        height="10vh"
        px="10"
        backgroundColor="blackAlpha.50"
        backdropBlur="20px"
        borderBottom="1px"
        borderColor="gray.100"
      >
        <Flex alignItems="center" justifyContent="space-between" h="full">
          {arButton && (
            <Button data-meshkraft-sku={slug} colorScheme="blue" mr="auto">
              ∛ View in AR
            </Button>
          )}
          <Flex
            justifyContent="space-between"
            width="100%"
            h="full"
            gap="2"
            alignItems="center"
            mr={arButton ? "auto" : null}
            ml={arButton ? "-127px" : null}
          >
            <Flex>
              <Link isExternal href="https://artlabs.ai">
                <Image src="/artlabs.svg" alt="ARTLabs Logo" h="3rem" />
              </Link>
            </Flex>
            <Flex>
              <Heading as="h2" h="36px" color="#0B1C4C">
                Viewer
              </Heading>
            </Flex>
            <Flex justifyContent="flex-end">
              <Button
                colorScheme="twitter"
                onClick={() => window.open("https://twitter.com/artlabsai")}
                display="flex"
                alignItems="center"
                gap="2"
              >
                <FaTwitter /> {isMobile ? "" : "Twitter"}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default Footer;
