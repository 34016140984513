import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaPowerOff, FaTwitter } from "react-icons/fa";
import { onLogout } from "../utils/helpers";

function Header({ title, hasLogout }) {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      as="header"
      position="sticky"
      top="0"
      zIndex="999"
      w="full"
      h={{ base: "20vh", lg: "10vh" }}
      px="10"
      backgroundColor="whiteAlpha.500"
      backdropFilter="auto"
      backdropBlur="20px"
      borderBottom="1px"
      borderColor="gray.100"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap="2"
        h="full"
        width="100%"
        textAlign="center"
        position="relative"
      >
        <Flex
          position="absolute"
          transform="auto"
          left={{ base: "50%", lg: "0" }}
          top="50%"
          translateY="-50%"
          translateX={{ base: "-50%", lg: "0" }}
          h="full"
          gap="2"
          alignItems="center"
          justifyContent="center"
        >
          <Link isExternal href="https://artlabs.ai">
            <Image src="/artlabs.svg" alt="ARTLabs Logo" h="3rem" />
          </Link>
        </Flex>

        <Flex width="100%" pt={{ base: "32", lg: "0" }}>
          {title && (
            <Heading
              as="h2"
              size={isMobile ? "sm" : "lg"}
              h="36px"
              color="#0B1C4C"
              mx="auto"
            >
              {title}
            </Heading>
          )}
        </Flex>

        <Flex
          position="absolute"
          transform="auto"
          top="50%"
          translateY="-50%"
          right={0}
          alignItems="center"
          gap="2"
        >
          {hasLogout && (
            <Button
              onClick={onLogout}
              colorScheme="teal"
              size={isMobile ? "xs" : "sm"}
              variant="outline"
              rightIcon={!isMobile ? <FaPowerOff /> : null}
            >
              {isMobile ? <FaPowerOff /> : "Logout"}
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
export default Header;
