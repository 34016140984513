import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("meshkraftApiToken");

  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data ?? response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("meshkraftApiToken");
      localStorage.removeItem("loggedin-user");
      return window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
