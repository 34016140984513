import React, { useEffect } from "react";

const VTO = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const sku = searchParams.get("sku");
  let config = searchParams.get("config");

  useEffect(() => {
    loadSDK();
  });

  const loadSDK = () => {
    const existingScript = document.getElementById('vtoSDK');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://unpkg.com/meshkraft-vto@latest/dist/index.min.js';
      script.type = "module"
      script.id = 'vtoSDK';

      script.onload = activateVTO
      document.head.appendChild(script);
    }
  };

  function activateVTO() {
    // parse config
    if (config) {
      try {
        config = JSON.parse(config);
      } catch (error) { }
    }

    let _config = { showAction: false, ...config || {} };
    // init sdk 
    meshkraftVTO.init({token, config:_config});

    // start experience
    meshkraftVTO.startVTO(sku);

    // core events
    meshkraftVTO.addEventListener('stop-vto', () => {
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers["close-event"]) {
        window.webkit.messageHandlers["close-event"].postMessage(null);
      }
      
      if (window.AndroidInterface) {
        window.AndroidInterface.onEvent('close-event');
      }
    });

    // custom events
    meshkraftVTO.addEventListener('add-to-cart', (e) => {
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers["add-to-cart"]) {
        window.webkit.messageHandlers["add-to-cart"].postMessage(e.detail.message);
      }
      
      if (window.AndroidInterface) {
        window.AndroidInterface.onEvent('add-to-cart', e.detail.message);
      }
    });
  }

  return (
    <div></div>
  );
};

export default VTO;
