import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

const Footer = ({ slug, arButton }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Box
        as="footer"
        position="relative"
        top="0"
        width="100%"
        height="10vh"
        px={isMobile ? "2.5" : "10"}
        backgroundColor="blackAlpha.50"
        backdropBlur="20px"
        borderBottom="1px"
        borderColor="gray.100"
      >
        <Flex alignItems="center" justifyContent="space-between" h="full">
          {arButton && (
            <Button data-meshkraft-sku={slug} colorScheme="blue" mr="auto">
              ∛ View in AR
            </Button>
          )}
          <Flex
            justifyContent="space-between"
            width="100%"
            h="full"
            gap="2"
            alignItems="center"
            mr={arButton ? "auto" : null}
            ml={arButton ? "-127px" : null}
          >
            <Flex>
              <Link isExternal href="https://artlabs.ai">
                <Image
                  src="/artlabs.svg"
                  alt="ARTLabs Logo"
                  h={isMobile ? "2rem" : "3rem"}
                  mr="5px"
                  width="auto"
                  mt="auto"
                />
              </Link>
              <Image
                src="/x.svg"
                alt="ARTLabs Logo"
                h={isMobile ? "1rem" : "1.5rem"}
                mr="5px"
                width="auto"
                ml="5px"
                mt="auto"
                mb="5px"
                justifyContent="center"
                alignContent="center"
              />
              <Image
                src="/meltwater-champs.png"
                alt="Meltwater Champs Logo"
                h={isMobile ? "2rem" : "3rem"}
                ml="5px"
                mt="auto"
              />
            </Flex>
            {/* {!isMobile && (
              <Flex>
                <Heading as="h2" h="36px" color="#0B1C4C">
                  Viewer
                </Heading>
              </Flex>
            )} */}
            <Flex justifyContent="flex-end" gap="10">
              <Box
                as="button"
                onClick={() => window.open("https://ccauction.apxi.io/")}
                height="30px"
                lineHeight="1.2"
                border="1px"
                px="8px"
                borderRadius="md"
                transitionProper="common"
                transitionDuration="normal"
                fontSize="14px"
                fontWeight="semibold"
                bg="#081a4a"
                borderColor="#081a4a"
                color="white"
                _hover={{ bg: "#134794" }}
                _focus={{
                  boxShadow:
                    "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                }}
              >
                Place Bid
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default Footer;
