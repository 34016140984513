import { Box, Flex, Image } from "@chakra-ui/react";
import { ImportScript } from "../utils/helpers";
import React from "react";

const Home = () => {
  return (
    <>
      <ImportScript resourceUrl={"/js/countly.js"} />
      <Flex
        w="full"
        minH="100vh"
        as="main"
        alignItems="center"
        justifyContent="center"
      >
        <Box as="section">
          <Image
            src="./artlabs.svg"
            alt="logo"
            h={{ base: "7rem", lg: "10rem" }}
          />
        </Box>
      </Flex>
    </>
  );
};

export default Home;
