import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";

const ProductUpdateModal = ({ productName, canUpdate, onUpdate, btnText }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Button
        onClick={onOpen}
        isDisabled={!canUpdate}
        size="sm"
        zIndex={10}
        textTransform="capitalize"
      >
        {btnText}
      </Button>

      <Modal
        isCentered
        size={isMobile ? "xs" : "lg"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{btnText} Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex alignItems="center" gap="1">
              <Text fontWeight="bold" fontStyle="italic">
                {productName}
              </Text>
              <Text>will be {`${btnText}ed`}.</Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => {
                onUpdate();
                onClose();
              }}
              colorScheme="green"
              textTransform="capitalize"
            >
              {btnText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProductUpdateModal;
