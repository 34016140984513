import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

const NotFound = () => {
  return (
    <Flex
      px="1rem"
      minH="100vh"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Image
        src="/artlabs.svg"
        alt="ARTLabs Logo"
        h={{ base: "7rem", lg: "10rem" }}
      />
      <Box pt="10">
        <Text fontSize="1.5rem">Page not found</Text>
      </Box>
    </Flex>
  );
};

export default NotFound;
