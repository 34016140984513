const QA_PLATFORM_BADGE_STYLE = {
  published: {
    text: 'Published',
    colorScheme: 'green',
    variant: 'solid',
  },
  unpublished: {
    text: 'Unpublished',
    colorScheme: 'purple',
    variant: 'solid',
  },
  ready: {
    text: 'Ready',
    colorScheme: 'green',
    variant: 'outline',
  },
  wip: {
    text: 'Work In Progress',
    colorScheme: 'orange',
    variant: 'outline',
  },
  draft: {
    text: 'Draft',
    colorScheme: 'orange',
    variant: 'outline',
  },
};

export function QAPlatformStateGenerate(
  _qa_state,
  _published_at,
  _vtoAvailable,
  _userRole
) {
  if (!!_published_at || _qa_state === 'published') {
    return QA_PLATFORM_BADGE_STYLE['published'];
  } else if (_qa_state === 'unpublished') {
    return QA_PLATFORM_BADGE_STYLE['unpublished'];
  } else if (_qa_state === 'vto-ready') {
    return QA_PLATFORM_BADGE_STYLE['ready'];
  } else if (_qa_state === 'ar-ready' && !_vtoAvailable) {
    return QA_PLATFORM_BADGE_STYLE['ready'];
  } else {
    return _userRole === 'internal_qa' || _userRole === 'admin_qa'
      ? QA_PLATFORM_BADGE_STYLE['draft']
      : QA_PLATFORM_BADGE_STYLE['wip'];
  }
}
